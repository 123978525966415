.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

body {
  background-color: #282c34;
  color: white;
  font-family: monospace;
}

.App-link {
  color: #09d3ac;
}

#logo {
  font-size:6rem;
}

.address {
  font-size:1rem;
}
