body {
    padding-left:30px;
    padding-left:30px;
    padding-top:20px;
}

header {

    text-align:center;
}


p {
    max-width:800px;
    max-width: 800px;
    text-align: left;
}

footer {
    width:100%;

}

td {
  padding:20px;
}

table {
    padding-bottom: 100px;
}